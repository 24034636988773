@tailwind components;

@layer components {
    .general-lage-text {
        @apply text-[30px] text-[#858585];
    }

    .general-lage-text span {
        @apply text-[#eb8704];
    }

    .general-small-text {
        @apply text-[#858585];
    }

    .general-box-sh {
        @apply bg-white m-[80px_0px_50px_0px];
        box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.17);
    }

    .pointer-img {
        @apply relative top-[-45px];
    }

    .pointer-img img {
        @apply m-auto;
    }

    .general-box-txt {
        @apply text-[#858585] relative top-[-25px];
    }

    .price-txt {
        @apply flex p-[0px_40px] items-center;
    }

    .price-txt button {
        @apply bg-white text-[#70c60f] font-semibold p-[15px_5px] flex-1;
        @apply border-[1.5px] border-solid border-[#70c60f];
        box-shadow: 0 4px 6px #7797b242;
    }

    .price-txt span {
        @apply w-[60px] text-[18px] text-[#b7b7b79c];
    }

    .view-all-txt {
        @apply text-[#1f9db9] p-[10px_0px];
    }

}