@tailwind components;

@layer components {
    .add-content-container .page-header {
        @apply text-center mt-[20px];
    }

    .add-content-container .page-header h1 {
        @apply text-[32px] text-[#828282] font-normal flex justify-center;
    }

    .add-content-container .page-header h1 img {
        @apply ml-3;
    }

    .add-content-container .page-body .box-header {
        @apply bg-[#e6e6e6] flex;
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 26%);
    }

    .add-content-container .page-body .box-body {
        @apply bg-white p-[15px];
        box-shadow: 0 1px 6px 0 rgb(0 0 0 / 26%);
    }

    .add-content-container .page-body .box-header img {
        @apply cursor-pointer p-[10px] inline-block bg-[#eeb11a];
    }

    .add-content-container .page-body .box-header .header-label {
        @apply w-1/2 ml-[10px] p-[7px];
    }

    .add-content-container .page-body .box-body .btn-add-audience-div {
        @apply mt-3;
    }

    .add-content-container .page-body .box-body .btn-add-audience {
        @apply text-[#179ab7] border-dashed border-2 border-[#179ab7] w-1/3 p-[10px];
    }

    .add-content-container .page-body .box-body .btn-continue {
        @apply text-[#70c60f] border-solid border-[1px] border-[#70c60f] w-1/3 p-[10px];
    }

    .add-content-container .page-body .box-body .first {
        @apply flex h-[68px] flex-wrap mb-[15px];
        /* box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%); */
    }

    .add-content-container .page-body .box-body .first .label {
        @apply bg-[#eeb11a] p-[10px_25px] text-white;
    }

    .add-content-container .page-body .box-body .first .img {
        @apply flex p-[15px] items-center;
    }

    .add-content-container .page-body .box-body .input {
        @apply flex p-[22px_25px] w-full;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
    }

    .add-content-container .page-body .box-body .redirect {
        @apply text-[#dfa516] p-[10px_0px] text-center text-[24px];
    }

    .add-content-container .page-body .box-body .p-dropdown {
        @apply h-[68px] mb-[15px] flex-1;
    }

    .add-content-container .page-body .box-body .p-dropdown .p-dropdown-label {
        @apply flex items-center w-[395px];
    }

    .panel .p-dropdown-items .p-dropdown-item {
        @apply flex !important;
    }

    .panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item .label {
        @apply bg-[#eeb11a] p-[10px_25px] text-white;
    }

    .panel .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item .img {
        @apply flex p-[15px] items-center;
    }
}