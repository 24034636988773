@tailwind components;

@layer components {
    .header {
        @apply h-[99px] pt-[26px] pr-[25px] pb-[23px] pl-[26px] bg-gradient-to-b from-[#ea7f00] to-[#efb71d];
    }
   
    .w-140px {
        @apply w-[140px] mx-[20px];
    }
    
    .w-175px {
        @apply w-[175px] mr-[20px];
    }
    
    .w-215px {
        @apply w-[215px];
    }
    
    .input-bg input {
        @apply h-[50px] w-[100%] bg-[#fafafa59] outline-none rounded-[6px] py-[5px] px-[10px] text-[#fff] indent-[32px] text-[20px];
    }
    
    .input-bg .btn-bg button {
        @apply h-[50px] w-[100%] bg-[#fafafa59] outline-none rounded-[6px] py-[5px] px-[10px] text-[#fff] indent-[25px] text-[18px] text-left;
    }
    
    .input-bg input::placeholder {
        @apply text-[#fff] opacity-100;
    }
    
    .input-bg div {
        @apply relative;
    }
    
    .input-bg .search-btn button {
        @apply absolute top-[16px] left-[10px];
    }
    
    .input-bg .btn-bg button img {
        @apply absolute top-[16px] left-[10px];
    }
    
    .input-bg button img {
        @apply w-[20px];
    }
    
    .input-bg div.swich-btn {
        @apply bg-[#f5d6a6] top-0 right-0 w-[70px] absolute h-[100%] rounded-tr-[5px] rounded-br-[5px] text-center;
    }
    
    .input-bg div.swich-btn span {
        @apply block leading-[10px] text-[13px] text-[#e07f08] pb-[7px];
    }

    .input-bg div.swich-btn img {
        @apply inline-block;
    }

    .input-bg .w-215px button {
        @apply indent-[5px];
    }
}
    
    