@tailwind components;

@layer components {
    .containermap {
        @apply flex bg-white;
        box-shadow: 0 4px 6px #7797b242;
    }
    
    .cardcontainer {
        @apply m-0 p-[20px_0px];
    }
    
    .cardcontainer ul {
        @apply m-[0px] p-0;
    }
    
    .cardcontainer ul li {
        @apply list-none text-center m-[5px] bg-white pb-[30px];
        box-shadow: 0 4px 6px 0 rgba(183, 183, 183, 0.26);
    }
    
    .cardheader {
        @apply p-[12px_15px] bg-[#eeb11a] text-center text-white text-[16px];
    }
    
    .cardtime {
        @apply text-[#868686] text-[25px] font-[300];
    }
    
    .cardinfo {
        @apply text-[#6fd145] text-[18px] p-[15px_10px] text-center;
    }
    
    .cardinfoimg {
        @apply relative;
    }
    
    .cardinfoimg img {
        @apply absolute left-[50%] ml-[-25px] w-[50px] bottom-[-55px];
    }
    
    .headertext {
        @apply text-[22px] text-[#828282] p-[15px_0px] font-normal uppercase;
    }
    
    .large-addbtn {
        @apply m-[10px_0px];
    }
    
    .large-addbtn button {
        @apply p-[25px] text-[18px] text-[#179ab7] border-[2px] border-[#179ab7] border-dashed block bg-white w-[100%];
    }
    
    .blube-img {
        @apply border-r-[1px] border-r-[#ccc] border-solid;
    }
    
    .notifactiondiv {
        @apply flex bg-white items-center rounded-[4px] p-[0px_10px] m-[20px_0px];
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }
    
    .notifaction-info {
        @apply text-[#179ab7] text-[14px] pl-[10px] font-bold;
    }
    
    .close-div {
        @apply absolute right-[30px] cursor-pointer;
    }
    
    .notifaction-info span {
        @apply text-[#828282] text-[18px] font-normal;
    }
    
    .headertext input {
        @apply text-[#2563eb] bg-transparent border-0 outline-none text-[12px] relative z-[11] top-[-5px];
        @apply w-[190px] !important;
        box-shadow: inherit;
    }
    
    span.overviewcolor {
        @apply text-[#2563eb] text-[12px] relative left-[-15px] top-[-3px] z-[1];
    }
}



