@tailwind components;

@layer components { 
    .grid-view-container {
        @apply flex-wrap justify-between m-[7.5px];
        width: calc(50% - 15px);
    }
    
    .grid-view-card {
        box-shadow: 0 0 6px #0000002b;
    }
    
    .grid-view-header {
        @apply flex;
    }
    
    .grid-view-header-bg {
        @apply bg-[#eeb11a] flex text-white p-[5px] flex-1 relative;
    }
    
    .grid-view-header-bg:after {
        @apply z-10 h-0 w-0 border-solid border-b-[30px] border-b-[#fff] border-r-[40px] border-r-[#0000] absolute right-[-6px];
        --tw-border-opacity: 1;
        content: var(--tw-content);
        transform: rotate(-90deg);
    }
    
    .img100 {
        @apply w-[100%];
    }
    
    .grid-view-header-img {
        @apply w-[30px];
    }
    
    .grid-view-header-img img {
        @apply w-[15px] m-[5px_auto_auto_auto];
    }
    
    .grid-view-header-info {
        @apply m-0 leading-[14px];
    }
    
    .grid-view-header-info span {
        @apply uppercase block text-[14px];
    }
    
    .grid-view-header-info label {
        @apply text-[14px];
    }
    
    .grid-view-price {
        @apply w-[130px] opacity-40 text-[15px] flex items-center justify-center;
    }
    
    .grid-view-buton {
        @apply flex p-[15px] justify-between;
    }
    
    .grid-view-buton a {
        @apply text-[#179ab7];
    }
    
    .grid-view-buton button {
        @apply text-[#179ab7] p-[5px_10px] border-[1px] border-[#179ab7] border-dashed;
    }
    
    .grid-view-footer {
        @apply p-[10px_15px] justify-between flex;
        box-shadow: 0 0 6px #0000002b;
    }
    
    .grid-view-footer button {
        @apply text-[#179ab7];
    }
    
    .grid-view-footer button span {
        @apply text-[#179ab7] text-[20px];
    }
    
    .grid-view-footer button label {
        @apply text-[#179ab7];
        letter-spacing: 5px;
    }

}



