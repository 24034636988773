@tailwind components;

@layer components {
    .userTabHeader {
        @apply text-[#858585] text-[25px] text-center pb-[20px];
    }

    .userTabinfo {
        @apply text-[#828282] text-[15px] pt-[15px];
    }

    .registered-users div.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        @apply bg-[#eb8704] text-[#fff];
    }

    .registered-users .p-dropdown-trigger-icon {
        @apply text-[#eb8704] !important;
    }

    .registered-users .p-dropdown:not(.p-disabled).p-focus {
        @apply outline-offset-0 outline-0;
        border-color: rgb(245 158 11) !important;
        box-shadow: rgb(245 158 11) 0px 0px 0px 1px !important;
    }

    .registered-users .p-button:focus {
        box-shadow: rgb(255 255 255) 0px 0px 0px 2px, rgb(245 158 11) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 1px 2px !important;
    }

    .registered-users .p-button:enabled:hover,
    .registered-users .p-button:not(button):not(a):not(.p-disabled):hover,
    .registered-users .p-button:enabled:active,
    .registered-users .p-button:not(button):not(a):not(.p-disabled):active {
        background: #eb8704 !important;
        border-color: #eb8704 !important;
    }
}