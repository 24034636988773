@tailwind components;

@layer components {
    .signup-container {
        @apply flex p-[20px_10px];
    }
    
    .signup-left {
        @apply flex-1 m-[30px];
    }
    
    .signup-form {
        @apply m-0;
    }
    
    .signup-right {
        @apply flex-1 m-[30px];
    }
    
    .margin-top50 {
        @apply mt-[50px];
    }
    
    .signup-form.input-indent .form-input input {
        @apply indent-[5px] h-[45px];
    }
    
    .signup-form.input-indent .login-orange {
        @apply mt-[30px];
    }
    
    .signup-form.input-indent .form-input input[type='checkbox'] {
        @apply w-[25px] h-[25px];
    }
    
    .signup-form .sign-label {
        @apply text-white relative top-[-8px] left-[10px];
    }
    
    .signup-form .sign-label a {
        @apply text-[#ec920a];
    }
    
    .signup-right-hader {
        @apply text-white text-center;
    }
    
    .signup-video {
        /* background:url(../../../static/images/dummy.jpg); */
        @apply bg-white w-[100%] mt-[20px] h-[75%] flex items-center justify-center;
    }
    
    .signup-video img {
        @apply w-[75px] h-[100px];
    }

    .signup-inline {
        display: inline !important ;
    }

    .error-image  {
         background: url(../../../static/images/exclmationIcon.png) no-repeat;
         background-position: right 10px center; 
    }

    .login-signup-container .form-checkbox input {
        @apply w-[30px] h-[30px] bg-white  mt-[15px] p-[5px_7px] indent-[26px] relative;
    }

    .login-signup-container .form-checkbox {
        @apply relative grid;
    }

    .tooltip { 
        @apply w-[40px] h-[25px] absolute top-[25px] right-0 inline-block;
      }

      .tooltip .tooltiptext {  
        @apply invisible w-max h-max bg-red-600 text-white absolute z-[1] bottom-[50%] left-[100%] opacity-0;
        transition: opacity 0.3s;
      }
      
      .tooltip .tooltiptext::after {
        @apply absolute top-[100%] left-[50%] ml-[-5px] border-[5px] border-solid;
      }
      
      .tooltip:hover .tooltiptext {
        @apply visible opacity-100 absolute bg-red-600 text-white p-[4px] z-[9] bottom-0 right-[-98px] text-[11px] w-max top-0;
      }
      
}

