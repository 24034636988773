@tailwind components;

@layer components {
    .billing-card-view {
        @apply flex text-center mt-[70px] gap-[20px] items-end;
    }

    .billing-card {
        @apply bg-white p-[20px_0] flex-1 relative;
        box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.16);
    }

    .billing-card-img {
        @apply absolute top-[-40px] w-[100%] text-center;
    }

    .billing-card-img img {
        @apply m-auto;
    }

    .billing-card-info {
        @apply mt-[100px] text-[18px] leading-[30px] text-[#858585];
    }

    .billing-card-btn {
        @apply p-[20px_50px];
    }

    .billing-card-btn button {
        @apply border-solid border-[1.5px] whitespace-nowrap w-[100%] flex-1 m-[8px_0] p-[15px_10px] font-semibold block;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(112 198 15 / var(--tw-text-opacity));
        --tw-border-opacity: 1;
        border-color: rgb(112 198 15 / var(--tw-border-opacity));
        box-shadow: 0 4px 6px #7797b242;

    }

    .billing-card-starter {
        @apply min-h-[460px];
    }
}