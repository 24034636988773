@tailwind components;

@layer components {
    .content-container .page-header {
        @apply text-center;
    }

    .content-container .page-header h1 {
        @apply text-[32px] text-[#828282] p-[15px_0px] font-normal uppercase;
    }

    .content-container .page-tip {
        @apply flex bg-white items-center rounded-[4px] p-[0px_10px] m-[20px_0px];
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }

    .content-container .page-tip .label {
        @apply text-[#179ab7] text-[14px] pl-[10px] font-bold;
    }

    .content-container .page-tip .label span {
        @apply text-[#828282] text-[18px] font-normal;
    }

    .content-container .page-tip .label a {
        @apply text-[18px];
    }

    .content-container .page-tip .close-button {
        @apply absolute right-[30px] cursor-pointer;
    }
}