@tailwind components;

@layer components { 
    .analytics-container .p-tabview .p-unselectable-text {
        @apply w-[50%] bg-[#dbdbdb];
    }
    
    .analytics-container .p-tabview .p-highlight {
        @apply bg-white;
    }
    
    .analytics-container .p-tabview .p-unselectable-text .tab-header {
        @apply flex justify-center p-[15px] text-[24px] text-[#828282] font-light items-center;
    }
    
    .analytics-container .p-tabview .p-unselectable-text .tab-header span {
        @apply pl-[10px];
    }
    
    .analytics-container .p-tabview .p-tabview-nav {
        @apply border-b-0;
    }
    
    .analytics-container .card-view {
        @apply flex m-[0px_10px] pt-[50px];
    }
    
    .analytics-container .card {
        @apply flex-1 m-[10px];
    }
    
    .analytics-container .card-body {
        @apply bg-white p-[20px] text-center min-h-[255px];
        box-shadow: 0 0 6px 0 rgba(137, 137, 137, 0.45);
    }
    
    .analytics-container .card-body img {
        @apply m-auto;
    }
    
    .analytics-container .card-header {
        @apply text-[16px] uppercase text-center pb-[10px] text-[#424242] opacity-80;
    }
    
    .analytics-container .card span {
        @apply block mt-[10px];
        color: rgba(137, 137, 137, 0.85);
    }
    
    .analytics-container .card span a {
        @apply text-[#40a3e0] ml-[10px];
    }
    
    .analytics-container .p-tabview .panel-container {
        @apply block text-center;
    }
    
    .analytics-container .p-tabview .panel-container h1 {
        @apply text-[32px] text-[#858585] font-light not-italic leading-[1.19] tracking-normal pt-[2.5rem];
        font-stretch: normal;
    }
    
    .analytics-container .p-tabview .panel-container h1 span {
        @apply text-[#eb8b06];
    }
    
    .analytics-container .p-tabview .panel-container h3 {
        @apply text-[22px] text-[#858585] font-light not-italic leading-[1.58] tracking-normal;
        font-stretch: normal;
    }
    
    .analytics-container .p-tabview .p-tabview-panels {
        @apply p-0;
    }
    
    .analytics-container .p-tabview .p-tabview-panel {
        @apply p-0;
    }
    
    .analytics-container .p-tabview .p-tabview-panel .border-dashed {
        @apply mr-[15px];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .title {
        @apply text-[32px] text-[#858585] font-light not-italic leading-[1.19] tracking-normal;
        font-stretch: normal;
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header {
        @apply flex justify-between items-center p-[1.5rem_1rem];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .buttons {
        @apply flex;
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .buttons button {
        @apply flex items-center border-[1px] border-solid border-[#179ab7] text-[#179ab7] p-[5px_15px] m-[5px];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .buttons button img {
        @apply pl-[10px];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-inputtext {
        @apply border-[1px] border-solid border-[#179ab7] p-[0.25rem_0.75rem] border-r-0 rounded-none text-[#179ab7];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-inputtext:enabled:hover {
        @apply border-[#179ab7];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-inputtext:enabled:focus {
        @apply border-[#179ab7] outline-none ;
        box-shadow: none;
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-button {
        @apply text-[#179ab7] bg-white border-[1px] border-solid border-[#179ab7] border-l-0 rounded-none p-[0.25rem_1rem];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-button:enabled:hover {
        @apply text-[#179ab7] border-[#179ab7];
    }
    
    .analytics-container .p-tabview .panel-container .containermap-header .p-calendar .p-button:focus {
        box-shadow: none;
    }
    
}
