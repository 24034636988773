
@tailwind components;

@layer components {
    .switch-account-header .header {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#ea7f00]
    }
    .switch-account-content {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#efb71d] h-[100%];
    }
}

.p-sidebar .p-sidebar-header, .p-sidebar .p-sidebar-content {
    padding: 0px !important;
}
