@tailwind components;

@layer components {
    .billing-subscribed-header {
        @apply text-center pb-[20px] text-[25px];
        --tw-text-opacity: 1;
        color: rgb(133 133 133 / var(--tw-text-opacity));
        font-size: 25px;
    }

    .billing-list-view {
        @apply p-[24px_18px_22px_35px] bg-white flex items-center;
        box-shadow: 0 0 23px 0 rgba(0, 0, 0, 0.16);
    }

    .billing-list-info {
        @apply text-[18px] text-[#858585] p-[5px_40px] flex-1;
    }

    .billing-list-info span {
        @apply font-semibold;
    }

    .billing-list-button button {
        @apply border-solid border-[1.5px] flex-1 p-[15px_10px] font-semibold block whitespace-nowrap w-[100%] m-[15px_0px];
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(112 198 15 / var(--tw-text-opacity));
        --tw-border-opacity: 1;
        border-color: rgb(112 198 15 / var(--tw-border-opacity));
        box-shadow: 0 4px 6px #7797b242;
    }

    .billing-list-img {
        @apply w-[200px];
    }

    .billing-list-img img {
        @apply w-[100%];
    }

    .billing-list-subheader {
        @apply text-[18px] text-[#858585] p-[40px_10px_50px_10px] text-center;
    }

    .billing-list-subheader a {
        @apply text-[#2597c9];
    }
}