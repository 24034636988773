@tailwind components;

@layer components {
    .right-box-sh {
        @apply bg-white;
        box-shadow: 0 4px 6px 0 rgba(183, 183, 183, 0.64);
    }

    .img100 img {
        @apply w-[100%] h-[212px] mb-[10px];
    }

    .month-free {
        @apply p-[3px];
    }

    .month-free span {
        @apply text-[#ed9420];
    }

    .general-right-input {
        @apply p-[0px_40px_10px_40px];
    }

    .general-right-input input {
        @apply bg-white block w-[100%] mt-[10px] p-[8px_5px] outline-none;
        box-shadow: 0 0 8px #0003;
    }

    .general-right-input button {
        @apply text-[#70c60f] bg-white p-[5px_5px] m-[10px_0px_10px_0px] w-[100%] font-semibold;
        @apply border-[1.5px] border-solid border-[#70c60f];
        box-shadow: 0 4px 6px #7797b242;
    }
}