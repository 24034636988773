@tailwind components;

@layer components {
    .audiences-chart {
        @apply flex-1;
    }

    .audiences-chart .audiences-chart-header {
        @apply flex text-center bg-white;
    }

    .audiences-chart .audiences-chart-header .audiences-chart-header-highlight {
        @apply flex-1 grid bg-[#6fd145] text-white text-[13px] font-normal p-4 relative;
    }

    .audiences-chart .audiences-chart-header .audiences-chart-header-highlight span:last-child {
        @apply text-[20px];
    }

    .audiences-chart .audiences-chart-header .audiences-chart-header-dim {
        @apply flex-1 grid text-[#868686] text-[13px] font-normal p-4;
    }

    .audiences-chart .audiences-chart-header .audiences-chart-header-highlight:after {
        @apply border-solid border-t-[15px] border-t-[#0000] border-b-[15px] border-b-[#0000] border-l-[15px] absolute right-[-15px] top-[23px];
        --tw-border-opacity: 1;
        border-left-color: rgb(111 209 69 / var(--tw-border-opacity));
        --tw-content: "";
        content: var(--tw-content);
    }

    .audiences-chart-header-dim span:last-child {
        @apply text-[20px];
    }

    .p-tabview-panel {
        @apply p-[15px];
    }



}