@tailwind components;

@layer components { 
    .add-launch-container {
        @apply flex justify-around;
    }

    .add-launch-container .view {
        @apply w-8/12 m-12;
    }

    .add-launch-container .view .section-1 {
        @apply bg-white p-3 text-[28px] text-center leading-[1.21] text-[#4f4f4f];
        box-shadow: 0 2px 8px 0 rgb(0 0 0 / 14%);
    }

    .add-launch-container .view .section-1 span:last-child {
        @apply text-[#ec9a0e];
    }

    .add-launch-container .view .section-2, .add-launch-container .view .section-7 {
        @apply pt-[25px] pb-[10px] text-[28px] font-[300] text-center text-[#4f4f4f];  
    }

    .add-launch-container .view .section-3 {
        @apply p-[15px] text-[24px] leading-[1.42] text-center text-[#345f17] bg-[#eaf7da];
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
    }

    .add-launch-container .view .section-4 {
        @apply text-[#4f4f4f] text-[20px] font-[300];
    }

    .add-launch-container .view .section-4 span {
        @apply pl-[5px];
    }

    .add-launch-container .view .section-5  {
        @apply p-8;
    }

    .add-launch-container .view .section-5 span {
        @apply uppercase text-[#f51615] justify-center items-center pt-[5px] text-[12px] flex;
    }

    .add-launch-container .view .section-6 {
        @apply bg-white p-[15px] flex;
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 17%);
    }

    .add-launch-container .view .section-6 .icon {
        @apply p-[20px];
    }

    .add-launch-container .view .section-6 .label {
        @apply text-[#4f4f4f] text-[20px] font-[300] leading-[1.4];
    }

    .add-launch-container .view .section-6 .label span {
        @apply text-[#1a9bb8] font-[400];
    }

    .add-launch-container .view .section-8 {
        @apply flex text-center;
    }

    .add-launch-container .view .section-8 .box {
        @apply flex-1 bg-white;
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 17%);
    }

    .add-launch-container .view .section-8 .box .box-header {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#ef9f1d] p-[10px] text-white text-[26px];
    }

    .add-launch-container .view .section-8 .box .box-body {
        @apply h-32 p-[20px] text-[#4f4f4f] text-[20px];
    }
}