@tailwind components;

@layer components {
    .audience-list-view .mapViewContainer img {
        @apply w-[100%] h-[100px];
    }

    .audience-list-view table.p-datatable-table tr th {
        @apply bg-[#8e8e8e] text-white font-semibold;
    }

    .audience-list-view table.p-datatable-table tr th div {
        @apply flex items-center;
    }

    .audience-list-view table.p-datatable-table tr th div img {
        @apply w-[15px] h-[15px] mr-[5px];
    }

    .audience-list-view table.p-datatable-table .blue-color {
        @apply text-[#179ab7];
    }

    .audience-list-view table.p-datatable-table .edit-delete-btn button {
        @apply text-[#179ab7] flex items-center mt-[10px];
    }

    .audience-list-view table.p-datatable-table .edit-delete-btn button img {
        @apply mr-[5px];
    }

    .audience-list-view table.p-datatable-table td.textCenter {
        @apply text-center;
    }

    .audience-list-view table.p-datatable-table .textCenter div {
        @apply justify-center;
    }

    .delete-model-info {
        @apply text-[18px] text-[#4f4f4f] text-center p-[0px_20px];
    }

    .delete-model-info span {
        @apply text-[#ff0000];
    }

    .delete-model-header {
        @apply text-center font-normal;
    }

    .p-dialog-footer .rejectbutton,
    .p-dialog-footer .acceptbutton {
        @apply hidden;
    }

    .delete-large-btn {
        @apply text-[#ea0a0a] bg-white border-solid border-[1.5px] border-[#ea0a0a] flex-1 p-[5px] flex w-[220px] text-center m-[25px_auto_auto_auto] justify-center relative text-[14px];
        box-shadow: 0 4px 6px #7797b242;
    }

    .delete-large-btn img {
        @apply absolute left-[10px] top-[7px] w-[12px];
    }

    .p-component-overlay .confirm-modal-dialog .p-dialog-header-close-icon {
        @apply text-[#ea7f00];
    }

    .p-component-overlay .confirm-modal-dialog .p-dialog-header .p-dialog-header-icon:focus {
        @apply outline-offset-0 outline-0;
        @apply shadow-none !important;
    }

}