@tailwind components;

@layer components {

    .steps-demo .active .rc-steps-item-content .rc-steps-item-title {
        @apply text-[#61ab0e] text-[12px];
    }

    .steps-demo .rc-steps-item-icon {
        @apply w-[60px] h-[60px] bg-[#fff] rounded-full !important;
        box-shadow: 0 0 9px 0 rgb(0 0 0 / 19%);
    }

    .steps-demo .active .rc-steps-item-icon {
        @apply border-solid border-[1px] border-[#70c60f] !important;
    }

    .steps-demo .rc-steps-icon {
        @apply top-[12px] left-[15px] !important;
    }

    .steps-demo .rc-steps-small .rc-steps-item-tail:after {
        @apply h-[4px];
    }

    .steps-demo .rc-steps-item-finish .rc-steps-item-tail:after {
        @apply bg-[#70c60f];
    }

    .steps-demo .rc-steps-item-tail {
        @apply top-[27px];
    }

    .steps-demo .rc-steps-item-content {
        @apply ml-[12px];
    }

    .steps-demo .rc-steps-label-vertical .rc-steps-item-tail {
        @apply p-[0px_15px_0_50px];
    }
}