@tailwind components;

@layer components {
    .footer {
        @apply px-6 py-6 bg-[#404040] relative;
        z-index: 11;
    }
    
    .footer-left-link li {
        @apply mr-[25px];
    }

    .footer-left-link li a {
        @apply text-[#00e0ff];
    }
}