@tailwind components;

@layer components {
    .pages-container .page-header {
        @apply text-center;
    }

    .pages-container .page-header h1 {
        @apply text-[32px] text-[#828282] p-[15px_0px] font-normal uppercase;
    }

    .pages-container .page-tip {
        @apply flex bg-white items-center rounded-[4px] p-[0px_10px] m-[20px_0px];
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }

    .pages-container .page-tip .label {
        @apply text-[#179ab7] text-[14px] pl-[10px] font-bold;
    }

    .pages-container .page-tip .label span {
        @apply text-[#828282] text-[18px] font-normal;
    }

    .pages-container .page-tip .label a {
        @apply text-[18px];
    }

    .pages-container .add-button {
        @apply m-[10px_0px];
    }

    .pages-container .add-button button {
        @apply p-[25px] text-[18px] text-[#179ab7] border-[2px] border-[#179ab7] border-dashed block bg-white w-[100%];
    }

    .pages-container .view .view-header {
        @apply flex bg-white;
        box-shadow: 0 0 6px #0000002b;
    }

    .pages-container .view .view-header .dim {
        @apply w-[130px] p-[15px] text-center;
    }

    .pages-container .view .view-header .dim span {
        @apply text-[22px] font-normal block text-[#868686];
    }

    .pages-container .view .view-header .highlight {
        @apply bg-[#6fd145] text-white w-[130px] p-[15px] text-center relative;
    }

    .pages-container .view .view-header .highlight:after {
        @apply border-solid border-t-[15px] border-t-[#0000] border-b-[15px] border-b-[#0000] border-l-[15px] absolute left-[-11px] top-[32px];
        --tw-border-opacity: 1;
        border-left-color: rgb(111 209 69 / var(--tw-border-opacity));
        --tw-content: "";
        content: var(--tw-content);
        transform: rotate(180deg);
    }

    .pages-container .view .view-header .highlight span {
        @apply text-white text-[22px];
    }

    .gride-tab-menu {
        @apply flex items-center;
    }

    .gride-tab-menu div {
        @apply p-[16px_20px] text-center cursor-pointer opacity-40;
    }

    .gride-tab-menu div img {
        @apply inline-block w-[35px];
    }

    .gride-tab-menu div span {
        @apply text-[14px] block mt-[5px];
    }

    .view-body {
        @apply m-[5px_0px];
    }

    .gride-tab-menu div.gride-tab-menu-active {
        @apply opacity-100;
        box-shadow: 0 0px 6px 0 rgb(0 0 0 / 16%);
    }
    
    .pages-container .view .view-header .dim span.small-font {
        @apply text-[14px];
    }
    
    .pages-container .view .view-header .highlight span.small-font {
        @apply text-[14px];
    }

}

