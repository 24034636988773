@tailwind components;

@layer components {
    .p-component-overlay .modal-dialog .p-dialog-header-close-icon {
        @apply text-[#ea7f00];
    }

    .p-component-overlay .modal-dialog div.p-dialog-content {
        @apply overflow-auto;
    }

    .p-component-overlay .modal-dialog .p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#ef9f1d] text-white border-0;
    }

    .p-component-overlay .modal-dialog .p-tabview .p-tabview-nav li a.p-tabview-nav-link {
        @apply opacity-70 bg-[#bcbcbc] text-white border-0 rounded-[0px] font-normal;
        @apply p-[1.25rem_2.5rem] !important;
    }

    .p-component-overlay .modal-dialog .p-tabview .p-tabview-nav li a.p-tabview-nav-link:hover {
        @apply opacity-70 border-0;
        @apply bg-[#bcbcbc] text-white !important;
    }

    .p-component-overlay .modal-dialog .p-tabview .p-tabview-nav li a.p-tabview-nav-link:focus {
        @apply border-0 shadow-none !important;
    }

    .p-component-overlay .modal-dialog .p-tabview-nav {
        @apply justify-between;
    }

    .p-component-overlay .modal-dialog .p-tabview-panels {
        @apply bg-white min-h-[250px];
        @apply p-0 !important;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.33);
    }

    .model-form-row {
        @apply flex pt-[19px] mr-[40px];
    }

    .model-form-row input,
    .model-form-row select {
        @apply outline-none bg-white h-[35px] p-[5px];
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    }

    .model-form-row label {
        @apply w-[90px];
    }

    .model-form-row label i {
        @apply text-[#ef9f1d];
    }

    .save-audience {
        @apply bg-white p-[5px] border-[1.5px] border-solid border-[#70c60f] text-[#70c60f] flex-1;
        box-shadow: 0 4px 6px #7797b242;
    }

    .p-component-overlay .modal-dialog .p-dialog-header .p-dialog-header-icon:focus {
        @apply outline-offset-0 outline-0;
        @apply shadow-none !important;
    }

    .p-component-overlay .modal-dialog .p-dialog-header .p-dialog-title {
        @apply font-normal !important;
    }

    .view-tutorial {
        @apply text-[12px] flex pt-[5px] justify-center items-center text-[#f60300] uppercase;
    }

    .view-tutorial img {
        @apply w-[25px] h-[20px];
    }
}