@tailwind components;

@layer components {

    .targeted-areas div.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
        @apply bg-[#eb8704] text-[#fff];
    }

    .targeted-areas .p-dropdown-trigger-icon {
        @apply text-[#eb8704] !important;
    }

    .targeted-areas .p-dropdown:not(.p-disabled).p-focus {
        @apply outline-offset-0 outline-0;
        border-color: rgb(245 158 11) !important;
        box-shadow: rgb(245 158 11) 0px 0px 0px 1px !important;
    }

    .targeted-areas .p-button:focus {
        box-shadow: rgb(255 255 255) 0px 0px 0px 2px, rgb(245 158 11) 0px 0px 0px 4px, rgb(0 0 0 / 0%) 0px 1px 2px !important;
    }

    .targeted-areas .p-button:enabled:hover,
    .targeted-areas .p-button:not(button):not(a):not(.p-disabled):hover,
    .targeted-areas .p-button:enabled:active,
    .targeted-areas .p-button:not(button):not(a):not(.p-disabled):active {
        @apply bg-[#eb8704] border-[#eb8704] !important;
    }

    .targeted-areas-header {
        @apply text-left pb-[20px] text-[25px];
        --tw-text-opacity: 1;
        color: rgb(133 133 133 / var(--tw-text-opacity));
    }

    .targeted-areas {
        @apply mb-[25px];
    }

    .targeted-areas .p-datatable-thead {
        @apply border-solid border-[1px];
        --tw-border-opacity: 1;
        border-color: rgb(204 204 204 / var(--tw-border-opacity));
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        box-shadow: 0 0 8px #391515;
    }

    .targeted-areas .p-datatable-table .p-datatable-tbody>tr>td {
        @apply p-[3px_5px] align-top;
    }

    .targeted-areas .p-datatable-table .p-datatable-thead>tr>th {
        @apply bg-[#0000] p-[10px_5px];
    }

    .targeted-areas td button {
        @apply border-solid float-right border-[1.5px] p-[5px_25px] font-semibold;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(112 198 15 / var(--tw-text-opacity));
        --tw-border-opacity: 1;
        border-color: rgb(112 198 15 / var(--tw-border-opacity));
        box-shadow: rgb(119 151 178 / 26%) 0px 4px 6px;
    }
}