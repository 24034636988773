@tailwind components;

.p-tabview {
    @apply flex-1
}

.containermap ul.p-tabview-nav .p-unselectable-text {
    @apply flex-1;
}

.containermap ul.p-tabview-nav .p-unselectable-text div {
    @apply h-[75px] bg-white rounded-[0] flex flex-col items-center justify-center shadow-[0_4px_6px_rgba(120,151,178,0.3)];
}

.containermap ul.p-tabview-nav .p-disabled {
    @apply opacity-100;
}

.containermap ul.p-tabview-nav .p-unselectable-text.p-highlight div {
    @apply bg-[#6fd145] text-white relative;
}

.containermap ul.p-tabview-nav .p-unselectable-text.p-highlight div span {
    @apply text-white;
}

.containermap ul.p-tabview-nav .p-unselectable-text div span {
    @apply text-[13px] font-normal block text-[#868686];
}

.containermap ul.p-tabview-nav .p-unselectable-text.p-highlight div::after {
    @apply content-[""] border-t-[15px] border-solid border-t-[#0000] border-b-[15px] border-b-[#0000] border-l-[15px] border-l-[#6fd145] absolute right-[-15px];
}

.containermap ul.p-tabview-nav .p-unselectable-text.p-highlight.left-arrow div::after {
    right: inherit;
    left: -14px;
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.containermap ul.p-tabview-nav .p-unselectable-text div span:last-child {
    @apply text-[20px];
}

.containermap ul.p-tabview-nav .p-unselectable-text.p-highlight div .p-tabview-title {
    @apply text-white;
}

.p-tabview ul.p-tabview-nav li.p-highlight .p-tabview-nav-link {
    @apply border-0;
}

.p-tabview-panel {
    @apply flex w-[100%] items-baseline;
}

.p-tabview-panel .p-chart {
    @apply flex-1;
}

.p-tabview-panel .p-chart canvas {
    @apply w-[100%] h-[200px] !important;
}

.p-tabview-panel button.border-dashed {
    @apply h-[130px] text-center w-[60px] text-[13px] relative bottom-[85px];
}

.p-tabview-panel button.border-dashed img {
    @apply m-auto;
}

