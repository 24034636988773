@tailwind components;

@layer components {
    .account-page .p-tabview .p-tabview-nav {
        @apply flex;
    }

    .account-page .p-tabview .p-tabview-nav li {
        @apply flex-1 m-[0px_1px_0px_0px];
    }

    .account-page .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        @apply rounded-none;
    }

    .account-page .p-tabview .p-tabview-nav li .p-tabview-nav-link {
        @apply bg-[#7272724a] border-[0px];
    }

    .account-page .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
        box-shadow: none;
    }

    .account-page .p-tabview .p-tabview-nav li a.p-tabview-nav-link {
        @apply m-[0_0_-5px] p-[20px_10px] text-[#7a797b];
    }

    .account-page .p-tabview-title {
        @apply flex w-[100%] justify-center font-medium;
    }

    .account-page .p-tabview-title img {
        @apply mr-[5px];
    }

    .general-tab-container {
        @apply flex;
    }

    .general-tab-info {
        @apply clear-both;
    }

    .account-page .p-tabview-panel {
        @apply block;
    }

    .general-left {
        @apply flex-1 text-center p-[15px_20px_15px_0px];
    }

    .general-right {
        @apply text-center flex-1 p-[15px_0px_15px_20px];
    }

    .userTabContainer {
        @apply flex;
    }

    .userTabLeft {
        @apply flex-1 pr-[25px];
    }

    .userTabRight {
        @apply w-[320px] pl-[15px];
    }

    .userTabLeft .p-datatable .p-datatable-thead>tr>th {
        @apply bg-transparent;
    }

    .userTabLeft .p-datatable-thead {
        @apply border-[1px] border-[#ccc] border-solid bg-[#fff];
        box-shadow: 0 0 8px #0005;
        box-shadow: 0 0 8px #391515;
    }

    .userTabLeft .p-datatable .p-datatable-tbody>tr>td,
    .userTabLeft .p-datatable .p-datatable-thead>tr>th {
        @apply p-[10px_5px];
    }

    .userTabLeft .p-datatable-tbody button {
        @apply mr-[15px];
    }

}