@tailwind components;

@layer components {

    .pages-list-item-header { 
        @apply flex bg-[#8e8e8e] mt-[5px];
    }
    
    .pages-list-item-header div { 
        @apply p-[10px_10px] flex-1 text-white text-[18px];
    }

    .pages-list-item div.pages-list-search, .pages-list-item-header div.pages-list-search { 
        @apply flex-none w-[50px];
    }

    .pages-list-item div.pages-list-search img, .pages-list-item-header div.pages-list-search img { 
        @apply w-[25px];
    }

    .p-dataview div.p-dataview-header { 
        @apply bg-transparent;
    }

    .pages-list-item { 
        @apply flex w-[100%] bg-white;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }

    .pages-list-item div { 
        @apply text-[18px] flex-1 p-[10px_10px] text-[#828282] font-thin;
    }

    .pages-list-item div button { 
        @apply flex mb-[1px] items-center;
    }
        
    .pages-list-item div button img { 
        @apply mr-[10px];
    }

    .pages-list-item-header div {
        @apply flex items-center;
    }
}