@tailwind components;

@layer components { 

     /* grid item css */
    .content-grid-item {
        @apply p-[19px_13px_16px_16px] rounded-[4px] bg-white m-[7.5px];
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.17);
        width: calc(33.3% - 15px);
    }

    .content-grid-item .content-grid-item-header {
        @apply flex;
    }

    .content-grid-item .content-grid-item-header img{
        @apply w-[26px] h-[24px] mr-[10px] relative top-[3px];
    }

    .content-grid-item .content-grid-item-header span{
        @apply text-[24px] text-[#179ab7];
    }
    
    .content-grid-item .content-grid-item-info {
        @apply p-[5px_0px] flex;
    }

    .content-grid-item .content-grid-item-info label {
        @apply whitespace-nowrap;
     }

    .content-grid-item .content-grid-item-info span {
        @apply text-[#179ab7] ml-[5px] whitespace-nowrap overflow-hidden text-ellipsis;
    }

    .content-grid-item .content-grid-item-click-info {
        @apply flex justify-between p-[10px_5px];
    }
      
    .content-grid-item .content-grid-item-click-info div { 
        @apply text-[#6f6f6f];
    }
      
    .content-grid-item .content-grid-item-click-info div span { 
        @apply text-[#818181] text-[20px] mr-[5px] uppercase;
    }
    
    .content-grid-item .progress-bar-info span {
        @apply text-[#7bc627];
    }
    
    .content-grid-item .content-grid-item-btn { 
        @apply mt-[25px] flex justify-between;
    }
     
    .content-grid-item .content-grid-item-btn button {
        @apply text-[#179ab7] whitespace-nowrap flex;
    }
    
    .content-grid-item .content-grid-item-btn button img {
        @apply relative top-[4px] mr-[5px];
    }

    .content-grid-item .progress-bar-container { 
        @apply flex items-center max-w-[290px] p-[15px_0px];
    }

    .content-grid-item .progress-bar {
        @apply w-[70px];
    }

    .content-grid-item .progress-bar-info {
        @apply flex-1 text-[18px] ml-[10px] leading-[18px];
    }

    
    /* list item css */
    .content-list-item-header { 
        @apply flex bg-[#8e8e8e] mt-[5px];
    }
    
    .content-list-item-header div { 
        @apply p-[10px_10px] flex-1 text-white text-[18px];
    }

    .content-list-item div.content-list-search, .content-list-item-header div.content-list-search { 
        @apply flex-none w-[50px];
    }

    .content-list-item div.content-list-search img, .content-list-item-header div.content-list-search img { 
        @apply w-[25px];
    }

    .p-dataview div.p-dataview-header { 
        @apply bg-transparent;
    }

    .content-list-item { 
        @apply flex w-[100%] bg-white;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.17);
    }

    .content-list-item div { 
        @apply text-[18px] flex-1 p-[10px_10px] text-[#179ab7];
    }

    .content-list-item div button { 
        @apply flex mb-[1px] items-center;
    }
        
    .content-list-item div button img { 
        @apply mr-[10px];
    }

    /* progress bar css */
    .percent-card {
        @apply flex items-center justify-center flex-col bg-white rounded-[5px];
    }
      
    .percent-card .percent {
        @apply relative;
    }
      
    .percent-card svg {
        @apply relative w-[60px] h-[60px];
        transform: rotate(-85deg);
    }
      
    .percent-card svg circle {
        @apply w-[100%] h-[100%] stroke-[#f0f0f0] stroke-[6];
        fill: none;
    }
      
    .percent-card svg circle:last-of-type {
        @apply stroke-[#3498db];
        stroke-dasharray: 158px;
        stroke-dashoffset: calc(158px - (158px * var(--percent)) / 100);
    }
    
    .percent-card:nth-child(1) svg circle:last-of-type {
        @apply stroke-[#7bc627];
    }
} 
