@tailwind components;

@layer components {
    
    .tab-view .p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#ef9f1d] text-white border-0;
    }

    .tab-view .p-tabview .p-tabview-nav li a.p-tabview-nav-link {
        @apply opacity-70 bg-[#bcbcbc] text-white border-0 rounded-[0px] font-normal text-center;
    }

    .tab-view .p-tabview .p-tabview-nav li a.p-tabview-nav-link:hover {
        @apply opacity-70 border-0;
        @apply bg-[#bcbcbc] text-white !important;
    }

    .tab-view .p-tabview .p-tabview-nav li a.p-tabview-nav-link:focus {
        @apply border-0 shadow-none !important;
    }

    .tab-view .p-tabview-nav {
        @apply justify-between;
    }

    .tab-view .p-tabview-nav .p-unselectable-text {
        @apply w-1/4;
    }

    .tab-view .p-tabview .p-tabview-nav li a.p-tabview-nav-link {
        @apply justify-center;
    }

    .tab-view .p-tabview-panels {
        @apply bg-white min-h-[250px];
        @apply p-0 !important;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.33);
    }

    .tab-view .p-tabview-panels .p-tabview-panel {
        @apply w-[100%] items-baseline block;
    }

    .add-audience-container .page-header {
        @apply text-center mt-[20px];
    }
    
    .add-audience-container .page-header h1 {
        @apply text-[32px] text-[#828282] font-normal;
    }

    .add-audience-container .page-body {
        @apply flex mt-8;
    }

    /* .add-audience-container .page-body-2 {
        @apply mt-8;
    } */

    .add-audience-container .page-body .item {
        @apply bg-white w-1/5 ml-[7px] mr-[7px] cursor-pointer;
        box-shadow: 0 4px 6px 0 rgb(119 151 178 / 26%);
    }

    .add-audience-container .page-body .item .item-img img {
        @apply w-full;
    }

    .add-audience-container .page-body .item .item-title {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#efb71d] text-[26px] text-white p-4 text-center h-[115px];
    }

    .add-audience-container .page-body .item .item-description {
        @apply text-[16px] p-4 text-center leading-tight opacity-70 pb-32;
    }

    .add-audience-container .page-footer {
        @apply p-[10px];
    }

    .add-audience-container .page-footer button {
        @apply text-[#70c60f] text-[22px] uppercase font-semibold;
    }

    .add-audience-container .success-message {
        @apply text-[#4c870a] bg-[#c8e7a5] opacity-70 p-4;
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item {
        @apply bg-white ml-[7px] mr-[7px] cursor-pointer mt-[15];
        box-shadow: 0 4px 6px 0 rgb(119 151 178 / 26%);
        width:calc(25% - 15px);
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item .item-img img {
        @apply w-full;
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item .item-title {
        @apply bg-gradient-to-b from-[#ea7f00] to-[#efb71d] text-[26px] text-white p-4 text-center;
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item .item-description {
        @apply text-[16px] p-4 text-center leading-tight opacity-70 inline-flex justify-center items-center w-full;
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item .item-description span {
        @apply ml-[10px];
    }

    .tab-view .p-tabview-panels .p-tabview-panel .item-add {
        @apply text-[#70c60f] text-[20px] font-semibold border-solid border-2 border-[#70c60f] text-center pt-32 min-h-[335px];
    }
    
    .add-audience-container .page-footer-2 {
        @apply p-[10px] justify-between flex;
    }

    .add-audience-container .page-footer-2 button {
        @apply text-[#70c60f] text-[22px] uppercase font-semibold;
    }

    .add-audience-container .page-footer-2 .btn-continue {
        @apply p-[0px_100px] bg-white border-solid border-[1px] border-[#70c60f];
    } 
}
