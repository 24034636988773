@tailwind components;

@layer components {

    .login-signup-bg {
        @apply relative;
        background-size: 100% !important;
        background: url(../../../static/images/login-bg-1.png);
    }

    .login-signup-bg:after {
        @apply absolute content-[''] top-[0px] left-[0px] h-[100%] w-[100%] bg-[#000000ba];
    }

    .login-signup-container {
        @apply relative z-[1];
    }

    .login-signup-container .login-heaer {
        @apply text-[30px] text-[#fff] text-center;
    }

    .login-signup-container .form-login {
        @apply max-w-[400px] m-[50px_auto];
    }

    .login-signup-container .form-input input {
        @apply w-[100%] h-[45px] bg-white outline-0 mt-[15px] p-[5px_7px] indent-[26px] relative;
    }

    .login-signup-container .form-input {
        @apply relative grid;
    }

    .login-signup-container .login-orange {
        @apply w-[100%] bg-[#ec920a] p-[12px_5px] text-[#fff] mt-[15px];
        box-shadow: 0 4px 6px #7797b242;
    }

    .login-signup-container .password-icon:after {
        @apply w-[15px] h-[19px] content-[''] absolute left-[9px] top-[28px];
        background: url(../../../static/images/lock.png);
        background-size: 100% 100%;
    }

    .login-signup-container .user-icon:after {
        @apply w-[18px] h-[19px] content-[''] absolute left-[9px] top-[28px];
        background: url(../../../static/images/user.png);
        background-size: 100% 100%;
    }

    .login-signup-container .login-card-row {
        @apply flex m-[0px_10px] pt-[50px];
    }

    .login-signup-container .login-card {
        @apply flex-1 m-[10px];
    }

    .login-signup-container .login-card-bg {
        @apply bg-white p-[20px] text-center min-h-[255px];
        box-shadow: 0 0 6px 0 rgba(137, 137, 137, 0.45);
    }

    .login-signup-container .login-card-bg img {
        @apply m-auto;
    }

    .login-signup-container .login-card-header {
        @apply text-[16px] text-white uppercase text-center pb-[10px];
    }

    .login-signup-container .login-card span {
        @apply block mt-[10px];
        color: rgba(137, 137, 137, 0.85);
    }

    .login-signup-container .login-card span a {
        @apply text-[#40a3e0] ml-[10px];
    }

    .error {
        @apply text-[#FF0000];
    }
}