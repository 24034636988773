@tailwind components;

.listContainer .p-datatable .p-datatable-header {
    @apply bg-white border-0;
}

.select-box {
    @apply m-[10px] pt-[15px];
}

.select-box ul {
    @apply m-[0px] p-[0px] flex justify-end;
}

.select-box ul li {
    @apply list-none mr-[15px] flex;
}

.select-box ul li select {
    @apply border-0 outline-none text-[#828282] text-[18px];
}

.select-box ul li img {
    @apply relative cursor-pointer;
}

.progress {
    @apply w-[90px] h-[90px] leading-[150px] bg-none m-[0_30px] shadow-none relative;
}

.progress:after {
    @apply content-[""] w-[100%] h-[100%] rounded-r-[50%] border-[12px] border-solid border-white absolute top-0 left-0;
}

.progress>span {
    @apply w-[50%] h-[100%] truncate absolute top-0 z-[1];
}

.progress .progress-left {
    @apply left-0;
}

.progress .progress-bar {
    @apply w-[100%] h-[100%] bg-none border-[12px] border-solid absolute top-0;
}

.progress .progress-left .progress-bar {
    @apply left-[100%] rounded-tr-[80px] rounded-br-[80px] border-l-0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
}

.progress .progress-right {
    @apply right-0;
}

.progress .progress-right .progress-bar {
    @apply left-[-100%] rounded-tl-[80px] rounded-bl-[80px] border-r-0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
    animation: loading-1 1.8s linear forwards;
}

.progress .progress-value {
    @apply w-[90%] h-[90%] text-[#7bc627] text-center bg-white rounded-[50%] text-[22px] leading-[80px] font-[100] absolute top-[5px] left-[5px];
}

.progress.blue .progress-bar {
    @apply border-[#7bc627]
}

.progress.blue .progress-left .progress-bar {
    animation: loading-2 1.5s linear forwards 1.8s;
}

.progress.yellow .progress-bar {
    @apply border-[#fdba04];
}

.progress.yellow .progress-left .progress-bar {
    animation: loading-3 1s linear forwards 1.8s;
}

.progress.pink .progress-bar {
    @apply border-[#ed687c];
}

.progress.pink .progress-left .progress-bar {
    animation: loading-4 0.4s linear forwards 1.8s;
}

.progress.green .progress-bar {
    @apply border-[#1abc9c];
}

.progress.green .progress-left .progress-bar {
    animation: loading-5 1.2s linear forwards 1.8s;
}

@keyframes loading-1 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
}

@keyframes loading-2 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(144deg);
        transform: rotate(144deg);
    }
}

@keyframes loading-3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}

@keyframes loading-4 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(36deg);
        transform: rotate(36deg);
    }
}

@keyframes loading-5 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(126deg);
        transform: rotate(126deg);
    }
}

@media only screen and (max-width: 990px) {
    .progress {
        @apply mb-[20px];
    }
}

.listContainer .p-datatable-table tr th {
    @apply text-[12px] p-[5px_7px] font-[100] !important;
}

.table-thbg span {
    @apply inline-block bg-[#fdba04] p-[5px] cursor-pointer;
}

.table-thbg span img {
    @apply w-[20px];
}

.listContainer .p-datatable-table tr.p-rowgroup-header td {
    @apply p-0 text-white !important;
}

.table-thbg label {
    @apply bg-white flex-1 text-[#179ab7] h-[29px] flex items-center pl-[5px] relative;
}

.listContainer .p-datatable-table button {
    @apply text-[#179ab7] border-[1px] border-[#179ab7] border-dashed;
}

.listContainer .p-datatable-table tr td:first-child {
    @apply indent-[25px];
}

.listContainer .p-datatable-table .p-rowgroup-header tr td {
    @apply indent-0;
}

.table-thbg:after {
    @apply w-0 h-0 content-[""] z-[1] border-b-[23px] border-solid border-b-[#c6c6c6] border-r-[29px] border-r-[#0000] absolute right-[-3px];
    transform: rotate(-90deg);
}

.listContainer .p-datatable-table tr th:nth-child(1),
.listContainer .p-datatable-table tr td:nth-child(1) {
    @apply w-[35%];
}

.listContainer .p-datatable-table tr th:nth-child(2),
.listContainer .p-datatable-table tr td:nth-child(2) {
    @apply w-[17%];
}

.listContainer .p-datatable-table tr th:nth-child(3),
.listContainer .p-datatable-table tr td:nth-child(3) {
    @apply w-[17%];
}

.listContainer .p-datatable-table tr th:nth-child(4),
.listContainer .p-datatable-table tr td:nth-child(4) {
    @apply w-[14%];
}

.table-thbg {
    @apply flex items-center relative;
}

.listContainer table.p-datatable-table tr.p-rowgroup-header {
    @apply bg-[#c6c6c6];
}

.listContainer .p-datatable-table tr td {
    @apply text-[12px] font-[500] p-[5px_7px] !important;
}

.p-sortable-column .p-column-header-content {
    @apply relative;
}

.p-sortable-column .p-column-header-content img {
    @apply absolute w-[25px] top-[-3px] right-[10px];
}

.p-sortable-column .p-column-header-content .p-sortable-column-icon {
    @apply text-[12px];
}

.progress-bardiv {
    @apply flex items-center;
}

.progress-head {
    @apply text-[22px] font-[100];
}

.progress-info {
    @apply text-[16px] font-[100] w-[31%];
}

.progress-edit {
    @apply m-[0_20px];
}

.progress-edit button {
    @apply uppercase text-[#70c60f] border-[1px] border-solid border-[#70c60f] p-[10px_35px] text-center text-[18px] w-[250px] shadow-[0_4px_6px_rgba(120,151,178,0.3)];
}

.listContainer .p-datatable-table tr.p-rowgroup-footer {
    @apply h-[30px];
}

.link {
    @apply text-[#179ab7] cursor-pointer;
}

.pagelist div.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    @apply border-[0px] bg-[#ec9a0e] text-white;
}
