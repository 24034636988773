@tailwind components;

@layer components {
    .starter-popup {
        @apply bg-white;
        @apply rounded-[10px] !important;
    }

    .starter-popup.p-dialog .p-dialog-header,
    .starter-popup.p-dialog .p-dialog-content {
        @apply bg-transparent;
    }

    .starter-popup.p-dialog .p-dialog-header {
        @apply absolute right-0;
    }

    .starter-popup .p-dialog-header-close-icon.pi.pi-times {
        @apply text-[#ea7f00] text-[25px];
    }

    .starter-popup.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
        @apply shadow-none;
    }

    .starter-popup.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
        @apply bg-transparent;
    }

    .starter-popup .starter-popup-img {
        @apply m-[30px_0px];
    }

    .starter-popup .starter-popup-img img {
        @apply m-auto;
    }

    .starter-popup .popup-form {
        @apply m-[20px_40px_10px_40px];
    }

    .starter-popup .popup-form div {
        @apply flex items-center;
    }

    .starter-popup .popup-form label {
        @apply mr-[20px] w-[210px] text-right;
    }

    .starter-popup .popup-form input {
        @apply outline-offset-2 outline-2 outline outline-[#0000] mt-[10px] p-[10px_5px] block flex-1;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        box-shadow: 0 0 8px #0003;
    }

    .popup-form-footer {
        @apply p-[0px_40px_10px_40px];
    }

    .starter-popup .popup-sub-btn button {
        @apply border-solid border-[1.5px] text-center whitespace-nowrap w-[100%] flex-1;
        @apply m-[8px_0] p-[11px_10px] font-semibold block;
        --tw-bg-opacity: 1;
        background-color: rgb(255 255 255 / var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgb(112 198 15 / var(--tw-text-opacity));
        --tw-border-opacity: 1;
        border-color: rgb(112 198 15 / var(--tw-border-opacity));
        box-shadow: 0 4px 6px #7797b242;
    }

    .starter-popup .billing-list-sub-head {
        @apply p-[10px_0] text-center;
    }

    .starter-popup .billing-list-sub-head a {
        @apply text-[#2597c9];
    }

    .starter-popup .popup-footer-img img {
        @apply m-auto;
    }
}