@tailwind components;

@layer components {
    .geotargetting {
        @apply text-center text-[#4c4c4cad] text-[28px] pb-[35px];
    }

    .general-item {
        @apply border-b-[1px] border-b-[#ccc] border-solid p-[20px_0px] flex;
    }

    .general-item-img {
        @apply w-[300px];
    }

    .general-item-img img {
        @apply w-[100%];
    }

    .general-item-info {
        @apply leading-[25px] flex-1 ml-[10px];
    }

    .general-item-header {
        @apply text-[#1f9db9] text-[22px];
    }

    .blue-btn {
        @apply m-[10px_auto] w-[350px];
    }

    .blue-btn button {
        @apply text-[#70c60f] w-[100%] bg-white m-[10px_0] p-[7px_5px] uppercase font-semibold;
        @apply border-[1.5px] border-solid border-[#70c60f];
        box-shadow: 0 4px 6px #7797b242;
    }
}