@tailwind components;

@layer components {
    .userTabinput {
        @apply m-0;
    }

    .userTabinput input,
    .userTabinput textarea {
        @apply w-[100%] bg-[#fff] outline-none mt-[10px] p-[10px_5px] block;
        box-shadow: 0 0 8px #0003;
    }

    .userTabinput .price-txt {
        @apply mt-[15px] p-[0px];
    }

    .userTabinput .price-txt button {
        @apply p-[10px_5px];
    }

    .userTabinput textarea {
        @apply h-[100px];
    }
}