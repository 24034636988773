@tailwind components;

@layer components {
    .sidebar {
        @apply h-screen bg-white pt-8 pr-8 pb-8 pl-12;
    }

    .support-link {
        @apply mt-[70px];
    }

    .support-link ul {
        @apply m-0;
    }

    .support-link ul li {
        @apply flex items-center;
    }

    .support-link ul li span {
        @apply bg-[#ec9a0e] inline-block w-[55px] h-[55px] rounded-[100%] text-center relative ml-[-10px] z-[1];
    }

    .support-link ul li a {
        @apply pt-[6px] pr-[34px] pb-[5px] pl-[27px] bg-[#fff] text-[#747474] text-[18px] h-[36px] relative left-[-10px];
        box-shadow: 0 0 6px 0 rgb(179 179 179 / 50%);
    }

    .support-link ul li img {
        @apply inline-block mt-[18px];
    }

}