@tailwind components;

@layer components {
    .input-field {
      @apply border-2 border-cyan-500;
    }
    .input-field .p-inputtext{
        @apply border-0;
    }
    .input-field .p-button, .input-field .p-button:enabled:hover {
        @apply text-cyan-500 bg-white;
    }
    .input-field .p-button, .input-field .p-button:focus {
        @apply border-0 shadow-none;
    }
    .input-field .p-button .pi-calendar {
        @apply text-2xl;
    }

    .error-lable {
       @apply text-red-700;
    }



    /* ------- */
    .section-modal .form-input input {
        @apply w-[100%] h-[45px] bg-white outline-0 p-[5px_7px] relative rounded-md;
    }

    .section-modal .form-input select {
        @apply w-[100%] h-[45px] bg-white outline-0 p-[5px_7px] relative rounded-md;
    }

    .section-modal .form-input {
        @apply relative grid;
    }

    .section-modal .tooltip:hover .tooltiptext {
        @apply left-[-115px];
    }

    .section-modal select {
        @apply appearance-none p-[10px] rounded-md;
    }

    .section-modal select.one {
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='%2326749a'><polygon points='0,0, 50,0 25,25'/></svg>")
        no-repeat;
       background-size: 14px;
      background-position: calc(100% - 10px) 17px;
      background-repeat: no-repeat;
    }
    
    .section-modal .tooltipnext { 
        @apply w-[40px] h-[25px] relative inline-block;
      }

      .section-modal .tooltipnext .tooltiptextnext {  
        @apply invisible w-max h-max bg-red-600 text-white absolute z-[1] bottom-[50%] left-[100%] opacity-0;
        transition: opacity 0.3s;
      }
      
      .section-modal .tooltipnext .tooltiptextnext::after {
        @apply absolute top-[100%] left-[50%] ml-[-5px] border-[5px] border-solid;
      }
      
      .section-modal  .tooltipnext:hover .tooltiptextnext {
        @apply visible opacity-100 absolute bg-red-600 text-white p-[4px] z-[9] bottom-0 right-[-98px] text-[11px] w-max top-0;
      }
      
}

.modal-dialog {
    border: 4px solid #09bce3 !important;
}

.p-dialog-header-icon {
    color: #09bce3 !important;
    border-color: #09bce3 !important;
}

.p-dialog-header-icon:focus {
    color: #09bce3 !important;
    border-color: #09bce3 !important;
}

.p-dialog, .p-dialog .p-dialog-header, .p-dialog .p-dialog-footer {
    border-radius: 0px !important;
}

.p-dropdown-trigger-icon {
    color: #09bce3 !important;
}

  