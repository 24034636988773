@tailwind components;

@layer components {
    .audiences-container .page-header {
        @apply text-center;
    }

    .audiences-container .page-header h1 {
        @apply text-[32px] text-[#828282] p-[15px_0px] font-normal uppercase;
    }

    .audiences-container .add-button {
        @apply m-[10px_0px];
    }

    .audiences-container .add-button button {
        @apply p-[25px] text-[18px] text-[#179ab7] border-[2px] border-[#179ab7] border-dashed block bg-white w-[100%] text-center;
    }

    .audiences-container .add-button .page-tip {
        @apply flex bg-white items-center justify-center;
    }

    .audiences-container .add-button .page-tip .label {
        @apply text-[#179ab7] text-[14px] pl-[10px] font-bold;
    }

    .audiences-container .add-button .page-tip .label span {
        @apply text-[#828282] text-[18px] font-normal;
    }

    .audiences-container .add-button .page-tip .label a {
        @apply text-[18px];
    }

    .audiences-container .add-button .page-tip img {
        @apply w-[40px];
    }

    .audiences-container .map-view {
        @apply flex bg-white mb-4;
        box-shadow: 0 4px 6px #7797b242;
    }
}