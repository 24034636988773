@tailwind components;

@layer components {   
    .login-signup-container .page-header {
        @apply text-center;
    }
    
    .login-signup-container .page-header h1 {
        @apply text-[32px] text-[#828282] p-[15px_0px] font-normal flex justify-center;
    }

    .add-content-container .page-header h1 img {
        @apply ml-3;
    }

    .login-signup-container .form-input label  {        
        @apply  text-[#828282] font-normal;
    }

    .login-signup-container .form-login {
        @apply max-w-[400px] m-[50px_auto];
    }

    .login-signup-container .form-input .input , select {        
        @apply h-[45px] bg-white outline-0 mt-[15px] p-[5px_7px] relative;
    }

    .login-signup-container .add_start {      
        @apply mt-[15px]  bg-white p-[5px] border-[1.5px] border-solid border-[#70c60f] text-[#70c60f] flex-1;
        box-shadow: 0 4px 6px #7797b242;
    }    

    .error {
        @apply text-[#000000] bg-[#f1a8a8] p-[5px];
    }

    i {
        @apply text-[#FF0000];
    }
    
}

