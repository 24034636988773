@tailwind components;

@layer components {
    .data-view .p-grid.grid.p-nogutter.grid-nogutter {
        @apply flex flex-wrap mt-[10px];
    }

    .data-view .p-dataview-content {
        @apply mb-[15px] clear-both;
        @apply bg-transparent !important;
    }

    .data-view div .p-button:focus {
        box-shadow: 0 0 0 2px #fff, 0 0 0 4px #ec9a0e, 0 1px 2px #0000;
    }
        
    .data-view div.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        @apply border-[0px] bg-[#ec9a0e] text-white;
    }

    .view-header {
        @apply flex bg-white;
        box-shadow: 0 0 6px #0000002b;
    }

    .data-view div.p-dataview-header { 
        @apply p-[0px];
    }
}